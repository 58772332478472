@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', 'Roboto', Arial, sans-serif;
}

a {
    text-decoration: none;
}

:root {
    --white: #ffffff;
    --black: #000000;
    --light-black: #0f0f11;

    --light-green: #308161;
    --primary-dark-green: #0d4962;
    --primary-green: #00857e;

    --lighter-purple: #802f58;
    --purple: #a66f8c;
    --dark-purple: #813059;

    --opacity: 0.8;

    --gray: #363636;
    --light-gray: rgba(14, 73, 98, 0.05);

    --box-shadow: 0 0 10px #0d496233;
}

/* 100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal // Open Sans regular
500	Medium
600	Semi Bold (Demi Bold)
700	Bold // open Sans bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy) */
